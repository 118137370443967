// import Swiper core and required modules
import { Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useRef, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "./PreviewSlide.css";

export default function PreviewSlide({ imagesData }) {
  const swiperTab = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-between space-x-5">
        <ChevronLeftIcon
          onClick={() => {
            if (currentIndex <= 0) {
              swiperTab.current.swiper.slideTo(imagesData.length - 1);
            } else {
              swiperTab.current.swiper.slideTo(currentIndex - 1);
            }
          }}
          className="h-[3rem] w-auto text-gray bg-gray-100 rounded-full p-2 hover:brightness-110 cursor-pointer"
        />
        <Swiper
          ref={swiperTab}
          // install Swiper modules
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          onSlideChange={(e) => setCurrentIndex(e.realIndex)}
          pagination={{ clickable: true }}
          className="pb-12 w-[90%] custom-pagination"
        >
          {imagesData?.map((x) => {
            return (
              <SwiperSlide>
                <img
                  className="w-full object-contain max-h-[80vh]"
                  src={x}
                  alt=""
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <ChevronRightIcon
          onClick={() => {
            if (currentIndex < imagesData.length - 1) {
              swiperTab.current.swiper.slideTo(currentIndex + 1);
            } else {
              swiperTab.current.swiper.slideTo(0);
            }
          }}
          className="h-[3rem] w-auto text-gray bg-gray-100 rounded-full p-2 hover:brightness-110 cursor-pointer"
        />
      </div>
      <div className="flex justify-end">
        <div className="text-white text-[1.15rem]">
          {currentIndex + 1} of {imagesData?.length}{" "}
        </div>
      </div>
    </div>
  );
}
