// import Swiper core and required modules
import { Autoplay, Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebaseConfig";

export default function BannerSlide() {
  const [bannerData, setBannerData] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "banners"), orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      let newArray = [];
      snapshot.docs.map((x) => newArray.push({ ...x.data(), id: x.id }));
      setBannerData(newArray);
    });
  }, []);

  return (
    <>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        navigation
        autoplay={{ delay: 2000 }}
        loop={true}
        grabCursor={true}
        pagination={{ clickable: true }}
        className="hidden md:block"
      >
        {bannerData?.map((x) => {
          return (
            <SwiperSlide>
              <img className="w-full object-cover" src={x.images} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        autoplay={{ delay: 2000 }}
        loop={true}
        grabCursor={true}
        pagination={{ clickable: true }}
        className="block md:hidden"
      >
        {bannerData?.map((x) => {
          return (
            <SwiperSlide>
              <img className="w-full object-cover" src={x.images} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
