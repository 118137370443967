// import Swiper core and required modules
import { Autoplay, Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { EyeIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function ProductSlide() {
  const [productData, setProductData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    onSnapshot(collection(db, "products"), (snapshot) => {
      let newArray = [];
      snapshot.docs.map((x) => newArray.push({ ...x.data(), id: x.id }));
      newArray.sort(
        (a, b) =>
          new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())
      );
      setProductData(newArray);
    });
  }, []);

  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={20}
      breakpoints={{
        1024: {
          slidesPerView: 4.5,
        },
        768: {
          slidesPerView: 3.5,
        },
        320: {
          slidesPerView: 1.5,
        },
      }}
      loop={true}
      grabCursor={true}
      autoplay={{ delay: 2000 }}
    >
      {productData.length === 0
        ? Array(null, Array(5)).map(() => {
            return (
              <SwiperSlide>
                <Skeleton height={250} />
              </SwiperSlide>
            );
          })
        : productData.map((x) => {
            return (
              <SwiperSlide>
                <div
                  onClick={() => navigate(`/product/${x.id}`)}
                  className="product-parent relative border drop-shadow-lg"
                >
                  <img className="w-full object-cover" src={x.banner} alt="" />
                  <div className="z-[10] font-semibold transition-all product-box absolute bottom-0 w-full left-0 bg-black/[0.6] text-white py-3 flex justify-center items-center">
                    {x.namaProduk.length > 20
                      ? x?.namaProduk?.substring(0, 20) + "..."
                      : x.namaProduk}
                  </div>
                  <div className="transition-all bg-black/[0.5]  product-child absolute w-full h-full left-0 top-0 flex items-center justify-center flex-col text-white cursor-pointer">
                    <EyeIcon className="h-[2rem] w-auto" />
                    <div>Check Detail</div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
    </Swiper>
  );
}
