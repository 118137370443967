import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { db, storage } from "../../firebaseConfig";

function ContentAdmin() {
  const [contentData, setContentData] = useState([]);
  const [file, setFile] = useState();
  const [deskripsi, setDeskripsi] = useState("");

  useEffect(() => {
    onSnapshot(collection(db, "content"), (snapshot) => {
      let newArray = [];
      snapshot.docs.map((x) => newArray.push({ ...x.data(), id: x.id }));
      setContentData(newArray);
    });
  }, []);

  const updateImage = async (e, id, imageUrl) => {
    const storageRef = ref(
      storage,
      `/content/${e.target.files[0].name + v4()}`
    );
    await uploadBytes(storageRef, e.target.files[0]);
    await getDownloadURL(storageRef)
      .then(async (url) => {
        updateDoc(doc(db, "content", id), {
          images: url,
        })
          .then(() => {
            const prevRef = ref(storage, imageUrl);
            deleteObject(prevRef);
          })
          .then(() => {
            toast("Gambar Berhasil Diubah");
          });
      })
      .catch((error) => toast(error.message));
  };

  const submitHandler = async (id, index) => {
    updateDoc(doc(db, "content", id), {
      deskripsi: contentData[index].deskripsi.replace(/\n/g, "<br>"),
    })
      .then(() => {
        toast("Deskripsi Berhasil Diubah");
      })
      .catch((error) => {
        if (error.message !== null) {
          toast(error.message);
        } else {
          toast("Something Went Wrong !");
        }
      });
  };

  return (
    <div className="mt-12">
      <div className="text-[1.5rem]">
        Konten untuk Landing Page Bagian Bawah
      </div>
      {contentData?.map((x, index) => {
        return (
          <div className="mt-5 flex flex-col space-y-3">
            <label
              htmlFor="file"
              className="image-parent relative cursor-pointer w-full h-[20rem] bg-gray-100 flex justify-center items-center"
            >
              <div className="image-child absolute left-0 top-0 w-full h-full bg-black/[0.5] flex items-center justify-center text-white text-[1.5rem]">
                Ubah Gambar
              </div>
              <img className="h-full object-contain" src={x.images} alt="" />
            </label>
            <input
              className="h-0 w-0"
              type="file"
              id="file"
              onChange={(e) => updateImage(e, x.id, x.images)}
            />
            <div className="text-[1.5rem]">Deskripsi</div>
            <textarea
              value={x.deskripsi.replace(/<br>/g, "\n")}
              onChange={(e) =>
                setContentData((prevState) => {
                  prevState[index].deskripsi = e.target.value;
                  return [...prevState];
                })
              }
              className="border outline-none p-2 rounded-md h-[10rem]"
              placeholder="Masukkan Deskripsi Konten"
            ></textarea>
            <button
              onClick={() => submitHandler(x.id, index)}
              className="bg-black text-white py-2 rounded-md w-full mt-5"
            >
              Ubah Deskripsi
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default ContentAdmin;
