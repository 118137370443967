import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import PreviewSlide from "../slides/PreviewSlide";

function ImagePreview({ preview, setPreview, imagesData }) {
  return (
    <div
      className={`${
        preview ? "visible opacity-1" : "invisible opacity-0"
      } transition-all fixed p-1 md:p-12 left-0 top-0 w-full h-screen z-[9999] flex items-center`}
      style={{ background: "rgba(0,0,0,0.3)" }}
    >
      <XMarkIcon
        onClick={() => setPreview(false)}
        className="p-2 bg-gray-100 rounded-full h-[3rem] w-auto absolute right-[1%] top-[2%] text-black cursor-pointer hover:brightness-110"
      />
      <div className="flex items-center justify-between w-full">
        <ChevronLeftIcon className="h-[3rem] w-auto" />
        <PreviewSlide imagesData={imagesData} />
        <ChevronRightIcon className="h-[3rem] w-auto" />
      </div>
    </div>
  );
}

export default ImagePreview;
