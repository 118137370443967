import { EyeIcon } from "@heroicons/react/24/outline";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import ImagePreview from "../components/popup/ImagePreview";
import { db } from "../firebaseConfig";

function ProductDetail() {
  const { id } = useParams();
  const [productDetail, setProductDetail] = useState({});
  const [preview, setPreview] = useState(false);
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await getDoc(doc(db, "products", id));
      setProductDetail(response.data());
      setLoading(false);
    };
    getData();
  }, [id]);

  return (
    <div className="p-5 xl:px-0 max-w-[1180px] mx-auto flex flex-col lg:flex-row lg:space-x-5">
      <ImagePreview
        preview={preview}
        setPreview={setPreview}
        imagesData={productDetail?.images}
      />
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="w-full lg:w-[50%]">
            <div className="grid grid-cols-3 gap-5">
              <div
                onClick={() => setPreview(true)}
                className="image-parent relative col-span-3 border drop-shadow-lg"
              >
                <div className="image-child cursor-pointer transition-all absolute w-full h-full flex flex-col space-y-3 justify-center items-center z-[888] bg-black/[0.4] text-white">
                  <EyeIcon className="h-[3rem] w-auto" />
                  <div>Preview in Slides</div>
                </div>
                <img
                  className="cursor-pointer w-full object-cover"
                  src={productDetail?.images?.[currentHighlightIndex]}
                  alt=""
                />
              </div>
              {productDetail?.images?.map((x, index) => {
                return (
                  index !== currentHighlightIndex && (
                    <img
                      onClick={() => setCurrentHighlightIndex(index)}
                      className="cursor-pointer border drop-shadow-lg"
                      src={x}
                      alt=""
                    />
                  )
                );
              })}
            </div>
          </div>
          <div className="w-full lg:w-[50%] flex flex-col space-y-2 mt-5 lg:mt-0">
            <div className="text-[1.5rem] lg:text-[2rem]">
              {productDetail?.namaProduk}
            </div>
            <div className="font-semibold text-[1.25rem]">
              Rp. {Number(productDetail?.harga)?.toLocaleString("id")}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: productDetail?.deskripsi }}
            ></div>
            <div className="font-semibold text-[1.25rem]">Varian</div>
            <div className="flex flex-col md:flex-row flex-wrap">
              {productDetail?.varian?.map((x, index) => {
                return (
                  <div
                    // onClick={() => setCurrentHighlightIndex(index)}
                    className={`border p-2 flex items-center space-x-2 hover:bg-gray-200 cursor-pointer transition-all mb-5 mr-3 w-full md:w-fit`}
                  >
                    <div
                      className="rounded-full w-[30px] h-[30px] drop-shadow-md"
                      style={{ background: x.warna }}
                    ></div>
                    <div>{x.namaVarian}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ProductDetail;
