const Colors = [
  {
    label: "Hitam",
    code: "#000000",
  },
  {
    label: "Putih",
    code: "#FFFFFF",
  },
  {
    label: "Merah",
    code: "FF0000",
  },
  {
    label: "Ungu",
    code: "#800080",
  },
  {
    label: "Hijau",
    code: "#008000",
  },
  {
    label: "Kuning",
    code: "#FFFF00",
  },
  {
    label: "Biru",
    code: "#0000FF",
  },
  {
    label: "Cokelat",
    code: "#a52a2a",
  },
];

export default Colors;
