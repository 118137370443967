import {
  PencilSquareIcon,
  PhotoIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import Categories from "../../data/Categories";
import Colors from "../../data/Colors";
import { db, storage } from "../../firebaseConfig";

function EditModal({ editMode, setEditMode, currentProductId }) {
  const [currentData, setCurrentData] = useState({});
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    onSnapshot(doc(db, "products", currentProductId), (snapshot) => {
      setCurrentData(snapshot.data());
    });
  }, [currentProductId]);

  const inputHandler = (e) => {
    setCurrentData((prevState) => {
      return {
        ...prevState,
        [e.target.id]: e.target.value.replace(/\n/g, "<br>"),
      };
    });
  };

  const updateHandler = async () => {
    try {
      await updateDoc(doc(db, "products", currentProductId), {
        namaProduk: currentData.namaProduk,
        kategori: currentData.kategori,
        deskripsi: currentData.deskripsi,
        varian: currentData.varian,
        harga: currentData.harga,
        images: currentData.images,
      });
      toast("Perubahan tersimpan");
      setEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateImage = async (e, index, imageUrl) => {
    if (
      e.target.files[0] !== null ||
      e.target.files[0] !== undefined ||
      e.target.files[0] !== ""
    ) {
      const storageRef = ref(
        storage,
        `products/${e.target.files[0].name + v4()}`
      );
      let newArray = [];
      if (currentData.images.length > 0 && currentData.images[0] !== "") {
        currentData.images.map((x) => newArray.push(x));
      }
      await uploadBytes(storageRef, e.target.files[0]);
      await getDownloadURL(storageRef)
        .then(async (url) => {
          newArray[index] = url;
          updateDoc(doc(db, "products", currentProductId), {
            images: newArray,
          });
        })
        .then(() => {
          if (imageUrl !== "") {
            const prevRef = ref(storage, imageUrl);
            deleteObject(prevRef);
          }
        })
        .then(() => {
          toast("Gambar berhasil diubah");
        })
        .catch((error) => {
          if (error.message !== null) {
            toast(error.message);
          } else {
            toast("Something went wrong !");
          }
        });
    }
  };

  const bannerHandler = async (e, imageUrl) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `/banner/${file.name + v4()}`);
    try {
      await uploadBytes(storageRef, file);
      await getDownloadURL(storageRef).then(async (url) => {
        updateDoc(doc(db, "products", currentProductId), {
          banner: url,
        })
          .then(() => {
            const prevRef = ref(storage, imageUrl);
            deleteObject(prevRef);
          })
          .then(() => {
            toast("Cover Berhasil Diubah");
          });
      });
    } catch (error) {
      if (error.message !== null) {
        toast(error.message);
      } else {
        toast("Something went wrong !");
      }
    }
  };

  const deleteProductImage = async (index, imageUrl) => {
    if (
      currentData.images[index] === "" ||
      currentData.images[index] === null ||
      currentData.images[index] === undefined
    ) {
      setCurrentData((prevState) => {
        prevState.images.splice(index, 1);
        return { ...prevState };
      });
    } else {
      let newArray = [...currentData.images];
      newArray.splice(index, 1);

      await updateDoc(doc(db, "products", currentProductId), {
        images: newArray,
      })
        .then(() => {
          const prevRef = ref(storage, imageUrl);
          deleteObject(prevRef);
        })
        .then(() => {
          toast(`Gambar Berhasil DiHapus`);
        })
        .catch((error) => {
          toast(error.message);
        });
    }
  };

  return (
    <div
      className={`${
        editMode ? "visible opacity-1" : "invisible opacity-0"
      } transition-all fixed z-[9999] left-0 top-0 right-0 bottom-0 w-full h-screen flex justify-center items-center`}
      style={{ background: "rgba(0,0,0,0.3)" }}
    >
      <div className="p-5 bg-white rounded-md w-[95%] md:w-[60%] lg:w-[40%] flex flex-col space-y-5 max-h-[95%] overflow-y-auto">
        <div className="flex items-center justify-between">
          <div className="text-[1.5rem]">Edit Produk</div>
          <XMarkIcon
            onClick={() => setEditMode(false)}
            className="h-[2rem] w-auto cursor-pointer"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label>Nama Produk</label>
          <input
            onChange={(e) => inputHandler(e)}
            value={currentData?.namaProduk}
            className="outline-none p-2 border w-full"
            id="namaProduk"
            placeholder="Masukkan Nama Produk"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label>Kategori</label>
          <select
            onChange={(e) => {
              setCurrentData((prevState) => {
                prevState?.kategori?.push(e.target.value);
                return { ...prevState };
              });
            }}
            className="outline-none p-2 border"
            id="kategori"
          >
            <option value="">Pilih Kategori</option>
            {Categories?.map((x) => {
              return <option value={x}>{x}</option>;
            })}
          </select>
        </div>
        <div className="flex flex-wrap mt-2">
          {currentData.kategori?.map((x, index) => {
            return (
              <div
                onClick={() =>
                  setCurrentData((prevState) => {
                    prevState.kategori.splice(index, 1);
                    return { ...prevState };
                  })
                }
                className="flex items-center mr-3 space-x-2 py-2 px-3 rounded-full bg-gray-100"
              >
                <div>{x}</div>
                <div className="p-1 bg-white rounded-full cursor-pointer">
                  <XMarkIcon className="h-[1rem] w-auto" />
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col space-y-2">
          <label>Deskripsi Produk</label>
          <textarea
            id="deskripsi"
            value={currentData?.deskripsi?.replace(/<br>/g, "\n")}
            onChange={(e) => inputHandler(e)}
            placeholder="Masukkan Deskripsi Produk"
            className="p-2 outline-none border h-[10rem]"
          />
        </div>

        {/* Cover / Banner Image  */}
        <div className="flex flex-col space-y-3">
          <label className="lg:text-[1.25rem]">
            Upload Gambar Untuk Cover Tampilan Depan
          </label>
          <div className="image-parent relative border rounded-md cursor-pointer w-full h-[20rem] flex justify-center bg-gray-100 items-center">
            <label
              htmlFor="banner-file"
              className="image-child transition-all absolute left-0 top-0 w-full h-full bg-black/[0.3] flex justify-end items-start p-2 cursor-pointer"
            >
              <PencilSquareIcon className="h-[2rem] w-auto text-white" />
            </label>
            <img
              className="h-full object-contain"
              src={currentData?.banner}
              alt=""
            />
          </div>
          <input
            onChange={(e) => bannerHandler(e, currentData?.banner)}
            className="h-0 w-0"
            id="banner-file"
            type="file"
          ></input>
        </div>

        {/* Upload Produk */}
        <div className="flex flex-col space-y-3">
          <label className="lg:text-[1.25rem]">
            Upload Gambar-Gambar Produk
          </label>

          {currentData?.images?.map((x, index) => {
            return (
              <div>
                <div className="relative border rounded-md cursor-pointer w-full h-[20rem] flex justify-center bg-gray-100 items-center hover:brightness-90">
                  <div className="absolute right-[1%] top-[3%] flex items-center justify-center space-x-3">
                    <label htmlFor={`product-${index}`}>
                      <PencilSquareIcon className="hover:bg-black hover:text-white cursor-pointer h-[2rem] w-auto bg-gray-200 p-1 rounded-full" />
                    </label>
                    {index > 0 && (
                      <TrashIcon
                        onClick={() =>
                          deleteProductImage(index, currentData.images[index])
                        }
                        className="cursor-pointer hover:bg-black hover:text-white h-[2rem] w-auto bg-gray-200 p-1 rounded-full"
                      />
                    )}
                  </div>

                  {currentData?.images?.[index] !== "" &&
                  currentData?.images?.[index] !== null &&
                  currentData?.images?.[index] !== undefined ? (
                    <img
                      className="h-full object-contain"
                      src={currentData?.images?.[index]}
                      alt=""
                    />
                  ) : (
                    <div className="flex flex-col space-y-2">
                      <PhotoIcon className="h-[2rem] w-auto" />
                      <div>Gambar Produk {index + 1}</div>
                    </div>
                  )}
                </div>
                <input
                  onChange={(e) =>
                    updateImage(e, index, currentData.images[index])
                  }
                  className="h-0 w-0"
                  id={`product-${index}`}
                  type="file"
                ></input>
              </div>
            );
          })}
          <button
            onClick={() =>
              setCurrentData((prevState) => {
                if (currentData.images.length > 0) {
                  currentData.images.push("");
                } else {
                  currentData.images = [""];
                }
                return { ...prevState };
              })
            }
            className="flex items-center justify-center space-x-5 bg-gray-100 py-2"
          >
            <PlusIcon className="h-[1.5rem] w-auto" />
            <div>Tambah Gambar</div>
          </button>
        </div>
        {/* Upload Produk End  */}

        {/* Varian Field  */}
        <div className="flex flex-col space-y-3">
          <label className="lg:text-[1.25rem]">Varian Produk</label>
          {currentData?.varian?.map((x, index) => {
            return (
              <div className="border p-5 drop-shadow-lg flex flex-col space-y-3">
                <div className="flex items-center justify-between">
                  <div className="text-[1.25rem] font-semibold">
                    Tipe Varian {index + 1}
                  </div>
                  {index > 0 && (
                    <div
                      onClick={() => {
                        setCurrentData((prevState) => {
                          prevState.varian.splice(index, 1);
                          return { ...prevState };
                        });
                        setBlocked(false);
                      }}
                      className="hover:bg-gray-100 p-2 rounded-full cursor-pointer"
                    >
                      <TrashIcon className="h-[1.5rem] w-auto" />
                    </div>
                  )}
                </div>
                <div className="flex flex-col space-y-2">
                  <label>Nama Varian</label>
                  <input
                    value={currentData?.varian?.[index]?.namaVarian}
                    onChange={(e) =>
                      setCurrentData((prevState) => {
                        prevState.varian[index].namaVarian = e.target.value;
                        return { ...prevState };
                      })
                    }
                    className="p-2 outline-none"
                    placeholder="Masukkan Nama Varian ( Contoh : PUTIH 1200 X 700 )"
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <label>Warna</label>
                  <select
                    value={currentData?.varian?.[index]?.warna}
                    onChange={(e) =>
                      setCurrentData((prevState) => {
                        prevState.varian[index].warna = e.target.value;
                        return { ...prevState };
                      })
                    }
                    className="p-2 outline-none"
                  >
                    <option value="">Pilih Warna</option>
                    {Colors?.map((x) => {
                      return <option value={x.code}>{x.label}</option>;
                    })}
                  </select>
                </div>
              </div>
            );
          })}
        </div>
        {/* Tombol Tambah Varian  */}
        {!blocked && (
          <div
            onClick={() => {
              setCurrentData((prevState) => {
                prevState.varian.push({
                  warna: "",
                  namaVarian: "",
                  gambar: "",
                });
                return { ...prevState };
              });
              setBlocked(true);
            }}
            className="cursor-pointer flex justify-center items-center bg-gray-100 py-2 space-x-3 rounded-md"
          >
            <PlusIcon className="h-[1.5rem] w-auto" />
            <div>Tambah Varian Baru</div>
          </div>
        )}
        <div className="flex flex-col space-y-2">
          <label>Harga</label>
          <input
            onChange={(e) => inputHandler(e)}
            value={currentData?.harga}
            type="number"
            className="outline-none p-2 border w-full"
            id="harga"
            placeholder="Masukkan Harga"
          />
        </div>
        <button
          onClick={() => updateHandler()}
          className="bg-black text-white py-2 rounded-md"
        >
          Simpan
        </button>
      </div>
    </div>
  );
}

export default EditModal;
