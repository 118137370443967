import React, { useState } from "react";
import { app } from "../firebaseConfig";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";

function Login() {
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({});
  const auth = getAuth();
  const navigate = useNavigate();

  const inputHandler = (e) => {
    setInputData((prevState) => {
      return {
        ...prevState,
        [e.target.id]: e.target.value,
      };
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (inputData.email && inputData.password) {
      try {
        await signInWithEmailAndPassword(
          auth,
          inputData.email,
          inputData.password
        );
        toast("Login Berhasil");
        navigate("/");
      } catch (error) {
        if (error.message !== null) {
          toast(error.message);
        } else {
          toast("Something went wrong !");
        }
      }
    } else {
      toast("Masukkan data yang dibutuhkan");
    }
    setLoading(false);
  };
  return (
    <div className="login-bg relative lg:h-screen p-5 p-12">
      <div className=" w-full h-full flex justify-center items-center">
        <form
          onSubmit={(e) => submitHandler(e)}
          className="w-[95%] md:max-w-[60%] lg:max-w-[40%] 2xl:max-w-[30%] p-5 rounded-md bg-white text-black flex flex-col space-y-5"
        >
          <div className="text-[1.5rem]">Login</div>
          <div className="flex flex-col space-y-2">
            <label>Email</label>
            <input
              onChange={(e) => inputHandler(e)}
              id="email"
              className="border p-2 outline-none"
              type="email"
              placeholder="Masukkan Email Anda"
            />
          </div>
          <div className="flex flex-col space-y-2">
            <label>Password</label>
            <input
              onChange={(e) => inputHandler(e)}
              id="password"
              className="border p-2 outline-none"
              type="password"
              placeholder="Masukkan Password Anda"
            />
          </div>
          <button
            className="w-full bg-black text-white rounded-md py-2 flex justify-center"
            type="submit"
          >
            {loading ? <Loading /> : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
