import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  HomeIcon,
  PhoneIcon,
  ShoppingBagIcon,
  UserCircleIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../assets/logo.png";

function Navigation() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [userActive, setUserActive] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserActive(true);
      } else {
        setUserActive(false);
      }
    });
  }, []);

  return (
    <div className="p-5 bg-black text-white flex items-center justify-between">
      <img
        onClick={() => navigate("/")}
        className="h-[2rem] md:h-[4rem] w-auto cursor-pointer"
        src={Logo}
        alt=""
      />
      <div className="hidden md:flex items-center space-x-5">
        <Link to="/">
          <div className="nav-parent">
            <div className="pb-1">Home</div>
            <div className={`nav-child transition-all`}></div>
          </div>
        </Link>
        <Link to="/products">
          <div className="nav-parent">
            <div className="pb-1">Products</div>
            <div className="nav-child transition-all"></div>
          </div>
        </Link>
        <Link to="/contact">
          <div className="nav-parent">
            <div className="pb-1">Contact</div>
            <div className="nav-child transition-all"></div>
          </div>
        </Link>
        {userActive ? (
          <div className="user-parent relative">
            <UserCircleIcon className="h-[2rem] w-auto cursor-pointer" />
            <div className="transition-all user-child absolute top-[120%] right-0 bg-white drop-shadow-lg text-black whitespace-nowrap z-[9999]">
              <div
                onClick={() => navigate("/admin")}
                className="py-2 px-5 flex items-center space-x-3 hover:bg-black hover:text-white cursor-pointer"
              >
                <UserIcon className="h-[1.25rem] w-auto" />
                <div>Admin Panel</div>
              </div>
              <hr></hr>
              <div
                onClick={() => {
                  auth.signOut();
                  navigate("/");
                  toast("Logged Out");
                }}
                className="flex items-center space-x-3 py-2 px-5 hover:bg-black hover:text-white cursor-pointer"
              >
                <ArrowRightOnRectangleIcon className="h-[1.25rem] w-auto" />
                <div>Log Out</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="pb-1">
            <Link
              to="/login"
              className="px-5 py-2 border border-white hover:bg-white hover:text-black transition-all rounded-md font-semibold"
            >
              Login
            </Link>
          </div>
        )}
      </div>
      <Bars3Icon
        onClick={() => setMobileMenu(!mobileMenu)}
        className="md:hidden h-[2rem] w-auto"
      />

      {/* Mobile Menu  */}
      <div
        className={`${
          mobileMenu ? "translate-x-0" : "translate-x-[-100%]"
        } transition-all fixed h-screen p-5 bg-white text-black left-0 top-0 bottom-0 z-[99999] w-[50%]`}
      >
        <div className="flex justify-end">
          <XMarkIcon
            onClick={() => setMobileMenu(false)}
            className="h-[1.5rem] w-auto"
          />
        </div>
        <div className="flex flex-col space-y-8 mt-5">
          <Link
            onClick={() => setMobileMenu(false)}
            to="/"
            className="flex items-center space-x-2"
          >
            <HomeIcon className="h-[1.5rem] w-auto" />
            <div>Home</div>
          </Link>
          <Link
            onClick={() => setMobileMenu(false)}
            to="/products"
            className="flex items-center space-x-2"
          >
            <ShoppingBagIcon className="h-[1.5rem] w-auto" />
            <div>Products</div>
          </Link>
          <Link
            onClick={() => setMobileMenu(false)}
            to="/contact"
            className="flex items-center space-x-2"
          >
            <PhoneIcon className="h-[1.5rem] w-auto" />
            <div>Contact</div>
          </Link>

          {userActive ? (
            <>
              <Link
                onClick={() => setMobileMenu(false)}
                to="/admin"
                className="flex items-center space-x-2"
              >
                <UserIcon className="h-[1.5rem] w-auto" />
                <div>Admin Panel</div>
              </Link>
              <div
                className="flex items-center space-x-2"
                onClick={() => {
                  auth.signOut();
                  navigate("/");
                  toast("Logged Out");
                  setMobileMenu(false);
                }}
              >
                <ArrowRightOnRectangleIcon className="h-[1.5rem] w-auto" />
                <div>Log Out</div>
              </div>
            </>
          ) : (
            <Link
              onClick={() => setMobileMenu(false)}
              to="/login"
              className="flex items-center space-x-2"
            >
              <UserIcon className="h-[1.5rem] w-auto" />
              <div>Login</div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navigation;
