import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { db, storage } from "../../firebaseConfig";

function BannerAdmin() {
  const [bannerData, setBannerData] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "banners"), orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      let newArray = [];
      snapshot.docs.map((x) => newArray.push({ ...x.data(), id: x.id }));
      setBannerData(newArray);
    });
  }, []);

  const uploadImage = async (e) => {
    const storageRef = ref(storage, `slider/${e.target.files[0].name + v4()}`);
    try {
      await uploadBytes(storageRef, e.target.files[0]);
      await getDownloadURL(storageRef).then(async (url) => {
        await addDoc(collection(db, "banners"), {
          images: url,
          createdAt: Timestamp.now(),
        }).then(() => {
          toast("Banner Berhasil Ditambahkan");
        });
      });
    } catch (error) {
      if (error.message !== null) {
        toast(error.message);
      } else {
        toast("Something Went Wrong !");
      }
    }
  };

  const deleteImage = async (imageUrl, id) => {
    const storageRef = ref(storage, imageUrl);
    deleteObject(storageRef)
      .then(() => {
        deleteDoc(doc(db, "banners", id)).then(() => {
          toast("Banner Berhasil Dihapus");
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const updateImage = async (e, id, imageUrl) => {
    const storageRef = ref(storage, `slider/${e.target.files[0].name + v4()}`);
    try {
      await uploadBytes(storageRef, e.target.files[0]);
      await getDownloadURL(storageRef).then(async (url) => {
        await updateDoc(doc(db, "banners", id), {
          images: url,
        })
          .then(() => {
            const prevRef = ref(storage, imageUrl);
            deleteObject(prevRef);
          })
          .then(() => {
            toast("Banner Berhasil Diubah");
          });
      });
    } catch (error) {
      if (error.message !== null) {
        toast(error.message);
      } else {
        toast("Something Went Wrong !");
      }
    }
  };

  return (
    <div className="mt-12">
      <div className="text-[1.25rem]">Banner Untuk Landing Page</div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-5 mt-5">
        {bannerData?.map((x) => {
          return (
            <div>
              <div
                htmlFor="add-banner"
                className="image-parent relative cursor-pointer w-full h-[10rem] flex flex-col items-center justify-center bg-gray-100"
              >
                <div
                  className="image-child absolute flex justify-end items-start left-0 top-0 w-full h-full p-2 text-white space-x-3"
                  style={{ background: "rgba(0,0,0,0.3)" }}
                >
                  <label htmlFor={`banner-${x.id}`}>
                    <PencilSquareIcon className="h-[2rem] w-auto cursor-pointer" />
                  </label>
                  <TrashIcon
                    onClick={() => deleteImage(x.images, x.id)}
                    className="h-[2rem] w-auto"
                  />
                </div>
                <img src={x.images} className="h-full object-contain" alt="" />
              </div>
              <input
                type="file"
                onChange={(e) => updateImage(e, x.id, x.images)}
                id={`banner-${x.id}`}
                className="h-0 w-0"
              />
            </div>
          );
        })}
        <div>
          <label
            htmlFor="add-banner"
            className="hover:brightness-90 cursor-pointer w-full h-[10rem] flex flex-col space-y-2 items-center justify-center bg-gray-100"
          >
            <PlusIcon className="h-[2rem] w-auto" />
            <div>Tambah Banner</div>
          </label>
          <input
            type="file"
            on
            onChange={(e) => uploadImage(e)}
            id="add-banner"
            className="h-0 w-0"
          />
        </div>
      </div>
    </div>
  );
}

export default BannerAdmin;
