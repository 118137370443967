const Categories = [
  "Education",
  "For Home",
  "Healthcare",
  "Industrial",
  "Kitchen",
  "Office",
  "Tools",
  "Custom Design",
];

export default Categories;
