import React from "react";
import Logo from "../assets/logo.png";
import { EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/outline";
import WhatsappIcon from "../assets/icons/whatsapp.svg";
import InstagramIcon from "../assets/icons/instagram.svg";
import MarketIcon from "../assets/icons/market.svg";
import { Link } from "react-router-dom";

function Footer() {
  const contactList = [
    {
      icon: <EnvelopeIcon className="h-[1.75rem] w-auto" />,
      label: "zmartdesk@gmail.com",
      link: "mailto: zmartdesk@gmail.com",
    },
    {
      icon: <img src={WhatsappIcon} alt="" className="h-[1.75rem] w-auto" />,
      label: "+62-838-9828-6678",
      link: "https://wa.me/6283898286678",
    },
    {
      icon: <MapPinIcon className="h-[1.75rem] w-auto" />,
      label:
        "Jl. Supriyadi No.21, RT.11/RW.6, Susukan, Kec. Ciracas, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13750",
      link: "https://www.google.com/maps?ll=-6.310566,106.874025&z=17&t=m&hl=en&gl=ID&mapclient=embed&q=Jl.+Supriyadi+No.21+RT.11/RW.6,+Susukan+Kec.+Ciracas+Kota+Jakarta+Timur,+Daerah+Khusus+Ibukota+Jakarta+13750",
    },
  ];
  return (
    <div>
      <div className="p-5 md:py-12 w-full bg-black text-white">
        <div className="flex flex-col lg:flex-row lg:space-x-16 max-w-[1180px] mx-auto">
          {/* Contact Information */}
          <div className="w-full">
            <img className="h-[5rem]" src={Logo} alt="" />
            <div className="flex flex-col space-y-3 mt-5 md:mt-12">
              {contactList?.map((x) => {
                return (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    key={x.label}
                    href={x.link}
                    className=""
                  >
                    <div className="flex items-center space-x-3">
                      <div>{x.icon}</div>
                      <div className="text-[1.1rem]">{x.label}</div>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>

          {/* Site Map */}
          <div className="w-full flex flex-col lg:flex-row lg:space-x-16 mt-5 lg:mt-0">
            <div className="flex flex-col">
              <div className="text-[1.5rem] font-semibold">Sitemap</div>
              <div className="flex flex-col space-y-2 mt-3">
                <Link className="hover:underline" to="/">
                  Home
                </Link>
                <Link className="hover:underline" to="/products">
                  Products
                </Link>
                <Link className="hover:underline" to="/contact">
                  Contact
                </Link>
                <Link className="hover:underline" to="/login">
                  Login
                </Link>
              </div>
            </div>
            <div className="flex flex-col mt-5 lg:mt-0">
              <div className="text-[1.5rem] font-semibold">
                Contact Information
              </div>
              <div className="flex items-center space-x-5 mt-3">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/zmart.xtender/"
                  className="hover:scale-[1.1] transition-all"
                >
                  <img className="h-[2rem] w-auto" src={InstagramIcon} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://wa.me/6283898286678"
                  className="hover:scale-[1.1] transition-all"
                >
                  <img className="h-[2rem] w-auto" src={WhatsappIcon} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tokopedia.com/sateku"
                  className="hover:scale-[1.1] transition-all"
                >
                  <img className="h-[2rem] w-auto" src={MarketIcon} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center p-5 bg-white text-black">
        © 2023 ZMART DESK. All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
