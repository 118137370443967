import {
  AdjustmentsHorizontalIcon,
  AdjustmentsVerticalIcon,
  MagnifyingGlassIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Categories from "../data/Categories";
import { db } from "../firebaseConfig";

function Products() {
  const [productData, setProductData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [currentFilter, setCurrentFilter] = useState("terbaru");

  const navigate = useNavigate();

  const filterList = [
    {
      filter: "terbaru",
      label: "Produk Terbaru",
    },
    {
      filter: "termurah",
      label: "Produk Termurah",
    },
    {
      filter: "termahal",
      label: "Produk Termahal",
    },
    {
      filter: "terlama",
      label: "Produk Terlama",
    },
  ];

  useEffect(() => {
    let q;
    if (currentCategory === "") {
      q = query(collection(db, "products"));
    } else {
      q = query(
        collection(db, "products"),
        where("kategori", "array-contains", currentCategory)
      );
    }
    onSnapshot(q, (snapshot) => {
      let newArray = [];
      snapshot.docs.map((x) => newArray.push({ ...x.data(), id: x.id }));
      newArray.sort(
        (a, b) =>
          new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())
      );
      setProductData(newArray);
      setFilteredData(newArray);
    });
  }, [currentCategory]);

  const searchHandler = (e) => {
    setFilteredData(
      productData.filter((x) =>
        x.namaProduk.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const sortHandler = (e) => {
    let newArray = [...filteredData];
    if (e === "terbaru") {
      newArray.sort(
        (a, b) =>
          new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())
      );
    } else if (e === "terlama") {
      newArray.sort(
        (a, b) =>
          new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate())
      );
    } else if (e === "termurah") {
      newArray.sort((a, b) => Number(a.harga) - Number(b.harga));
    } else if (e === "termahal") {
      newArray.sort((a, b) => Number(b.harga) - Number(a.harga));
    }
    setFilteredData(newArray);
  };

  return (
    <div className="p-5 xl:px-0 lg:max-w-[1180px] mx-auto">
      <div className="flex flex-col md:flex-row md:space-x-5">
        <div className="w-full p-2 bg-white rounded-md flex items-center space-x-2 border drop-shadow-md">
          <input
            onChange={(e) => searchHandler(e)}
            placeholder="Cari Nama Produk"
            className="outline-none bg-transparent flex-1"
          />
          <MagnifyingGlassIcon className="h-[1.25rem] w-auto text-gray-400" />
        </div>
        <div className="relative cursor-pointer image-parent mt-5 md:mt-0">
          <div className="flex items-center justify-between space-x-2 border rounded-md drop-shadow-lg h-full px-5 bg-white whitespace-nowrap py-2">
            <div>Filter Produk</div>
            <AdjustmentsHorizontalIcon className="h-[1.25rem] w-auto" />
          </div>
          <div className="image-child transition-all absolute top-[120%] z-[999] right-0 bg-white whitespace-nowrap bg-white drop-shadow-lg rounded-md">
            {filterList?.map((x) => {
              return (
                <>
                  <div
                    onClick={() => {
                      sortHandler(x.filter);
                      setCurrentFilter(x.filter);
                    }}
                    className={`${
                      currentFilter === x.filter && "bg-black text-white"
                    } py-2 px-5 hover:bg-black hover:text-white transition-all`}
                  >
                    {x.label}
                  </div>
                  <div className="h-[1px] bg-gray-200 w-full"></div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-5 bg-white drop-shadow-lg border">
        <div className="flex space-x-8 whitespace-nowrap overflow-x-auto px-5 border-b">
          <div
            id="tabs"
            onClick={() => {
              setCurrentCategory("");
            }}
            className={`${
              currentCategory === "" && "border-b-[2px] border-black"
            } cursor-pointer py-2`}
          >
            All Categories
          </div>
          {Categories.map((x) => {
            return (
              <div
                onClick={() => {
                  setCurrentCategory(x);
                }}
                className={`${
                  currentCategory === x && "border-b-[2px] border-black"
                } cursor-pointer py-2`}
              >
                {x}
              </div>
            );
          })}
        </div>
        {filteredData.length === 0 ? (
          <div className="flex flex-col justify-center items-center p-24 w-full text-gray-500 space-y-5">
            <NoSymbolIcon className="h-[3rem] w-auto" />
            <div>No Product Found</div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-5 gap-5">
            <AnimatePresence>
              {filteredData?.map((x) => {
                return (
                  <div
                    className="hover:translate-y-[-1%] transition-all"
                    key={x.id}
                  >
                    <motion.div
                      layout
                      transition={{ duration: 0.2 }}
                      initial={{ transform: "scale(0)" }}
                      animate={{ transform: "scale(1)" }}
                      exit={{ transform: "scale(0)" }}
                      onClick={() => navigate(`/product/${x?.id}`)}
                      className="bg-white drop-shadow-lg border hover:translate-y-[-1%] transition-all cursor-pointer"
                    >
                      <img
                        className="md:h-[15rem] xl:h-[18rem] object-fit w-full"
                        src={x?.banner}
                        alt=""
                      />
                      <div className="p-3 flex flex-col space-y-2">
                        <div className="text-[1.25rem]">{x?.namaProduk}</div>
                        <div className="text-[1.25rem] font-semibold">
                          Rp {Number(x?.harga)?.toLocaleString("id")}
                        </div>
                        <div>{x?.deskripsi?.substring(0, 100) + "..."}</div>
                      </div>
                    </motion.div>
                  </div>
                );
              })}
            </AnimatePresence>
          </div>
        )}
      </div>
    </div>
  );
}

export default Products;
