import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BannerSlide from "../components/slides/BannerSlide";
import ProductSlide from "../components/slides/ProductSlide";
import Shipping from "../assets/shipping.png";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig";

function Home() {
  const navigate = useNavigate();
  const [contentData, setContentData] = useState([]);

  useEffect(() => {
    onSnapshot(collection(db, "content"), (snapshot) => {
      let newArray = [];
      snapshot.docs.map((x) => newArray.push({ ...x.data(), id: x.id }));
      setContentData(newArray);
    });
  }, []);

  return (
    <div>
      <div>
        <BannerSlide />
      </div>
      <div className="bg-gray-100 py-5">
        <div className="p-5 xl:px-0 max-w-[1180px] mx-auto">
          <div className="flex items-center justify-between">
            <div className="text-[1.25rem] lg:text-[1.5rem] font-semibold">
              Produk Kami
            </div>
            <div
              onClick={() => navigate("/products")}
              className="font-semibold cursor-pointer hover:brightness-[90] hover:underline"
            >
              Lihat Semua
            </div>
          </div>
          <div className="mt-5">
            <ProductSlide />
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row max-w-[1180px] mx-auto lg:space-x-10 py-12 p-5 xl:px-0">
        {contentData?.map((x) => {
          return (
            <>
              <div className="w-full">
                <img className="border drop-shadow-md" src={x.images} alt="" />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: x.deskripsi }}
                className="flex flex-col space-y-5 w-full text-[1.25rem] mt-5 lg:mt-0"
              ></div>
            </>
          );
        })}
      </div>

      <div className="bg-gray-100 flex flex-col justify-center items-center p-5 py-12">
        <div className="flex flex-col md:flex-row items-center md:space-x-5">
          <img src={Shipping} alt="" />
          <div className="text-[1.5rem] text-center md:text-left md:text-[3rem] font-semibold">
            <span className="font-bold">FREE SHIPPING</span> <br />
            FOR <span className="font-bold">JAKARTA</span> AREA
          </div>
        </div>
        <div className="font-bold text-[1.25rem] md:text-[1.5rem] mt-5 md:mt-0 text-center lg:text-left">
          OUTSIDE JAKARTA, SHIPPING COST WILL BE PAID BY CUSTOMER
        </div>
      </div>
    </div>
  );
}

export default Home;
