// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCn-V_7UAsIr4BLcSRJu81Ydx2pun7BO7c",
  authDomain: "zmart-eaf96.firebaseapp.com",
  projectId: "zmart-eaf96",
  storageBucket: "zmart-eaf96.appspot.com",
  messagingSenderId: "914959908051",
  appId: "1:914959908051:web:c305f9570bee3e45df6826",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
