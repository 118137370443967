import React, { useState } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import Loading from "../components/Loading";
function Contact() {
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({
    email: "",
    nama: "",
    nomorTelepon: "",
    isiPesan: "",
  });

  const inputHandler = (e) => {
    setInputData((prevState) => {
      return {
        ...prevState,
        [e.target.id]: e.target.value,
      };
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      inputData.email.includes("@") &&
      inputData.nama.length >= 4 &&
      inputData.nomorTelepon &&
      inputData.nomorTelepon?.toString().length >= 10 &&
      inputData.isiPesan.length >= 10
    ) {
      try {
        await emailjs.send(
          "service_wec17df",
          "template_qpvoza3",
          {
            email: inputData.email,
            nama: inputData.nama,
            nomorTelepon: inputData.nomorTelepon,
            isiPesan: inputData.isiPesan,
          },
          "A0MZe1FtwqaN-YJEl"
        );
        toast("Pesan Terkirim");
      } catch (error) {
        toast(error.message);
      }
    } else if (!inputData.email.includes("@")) {
      toast("Masukkan email yang valid");
    } else if (inputData.nama.length < 4) {
      toast("Nama lengkap harus terdiri dari minimal 4 karakter");
    } else if (inputData.nomorTelepon.toString().length < 10) {
      toast("Nomor telepon harus terdiri dari minimal 10 digit angka");
    } else if (inputData.isiPesan.length < 10) {
      toast("Pesan harus terdiri dari minimal 10 karakter");
    } else {
      toast("Masukkan Data yang benar");
    }
    setLoading(false);
  };

  return (
    <div className="p-5 xl:px-0 max-w-[1180px] mx-auto flex flex-col md:flex-row justify-between md:space-x-5">
      <div className="w-full">
        <iframe
          className="w-full h-[400px] md:h-full"
          title="ZMART DESK Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.642826379101!2d106.87402499999999!3d-6.310565599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ed71a98fb2bf%3A0x64737fc0f400dd3b!2sJl.%20Supriyadi%20No.21%2C%20RT.11%2FRW.6%2C%20Susukan%2C%20Kec.%20Ciracas%2C%20Kota%20Jakarta%20Timur%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2013750!5e0!3m2!1sen!2sid!4v1673008546217!5m2!1sen!2sid"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <form
        onSubmit={(e) => submitHandler(e)}
        className="flex flex-col space-y-5 w-full"
      >
        <div className="flex flex-col space-y-3">
          <label>Email</label>
          <input
            value={inputData.email}
            id="email"
            onChange={(e) => inputHandler(e)}
            className="w-full p-2 rounded-md outline-none border"
            placeholder="Masukkan Email Anda"
          />
        </div>
        <div className="flex flex-col space-y-3">
          <label>Nama Lengkap</label>
          <input
            value={inputData.nama}
            id="nama"
            onChange={(e) => inputHandler(e)}
            className="w-full p-2 rounded-md outline-none border"
            placeholder="Masukkan Nama Lengkap Anda"
          />
        </div>
        <div className="flex flex-col space-y-3">
          <label>Nomor Telepon</label>
          <input
            type="number"
            value={inputData.nomorTelepon}
            id="nomorTelepon"
            onChange={(e) => inputHandler(e)}
            className="w-full p-2 rounded-md outline-none border"
            placeholder="Masukkan Nomor Telepon Anda"
          />
        </div>
        <div className="flex flex-col space-y-3">
          <label>Isi Pesan</label>
          <textarea
            value={inputData.isiPesan}
            id="isiPesan"
            onChange={(e) => inputHandler(e)}
            className="w-full p-2 rounded-md outline-none border h-[10rem]"
            placeholder="Masukkan Isi Pesan"
          />
        </div>
        <button
          className="w-full py-3 bg-black rounded-md text-white flex justify-center"
          type="submit"
        >
          {loading ? <Loading /> : "Kirim Pesan"}
        </button>
      </form>
    </div>
  );
}

export default Contact;
