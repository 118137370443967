import {
  PencilSquareIcon,
  PhotoIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import Loading from "../components/Loading";
import EditModal from "../components/popup/EditModal";
import BannerAdmin from "../components/tabs/BannerAdmin";
import ContentAdmin from "../components/tabs/ContentAdmin";
import Categories from "../data/Categories";
import Colors from "../data/Colors";
import { db, storage } from "../firebaseConfig";

function Admin() {
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);

  const [inputData, setInputData] = useState({
    namaProduk: "",
    deskripsi: "",
    kategori: [],
    harga: "",
  });

  const [varian, setVarian] = useState([
    {
      warna: "",
      namaVarian: "",
    },
  ]);

  // Upload Images
  const [productImages, setProductImages] = useState([""]);
  const [bannerImage, setBannerImage] = useState("");

  // Popup Edit Modal
  const [editMode, setEditMode] = useState(false);
  const [currentProductId, setCurrentProductId] = useState("");

  const [currentMode, setCurrentMode] = useState("Produk");

  const modeList = ["Produk", "Banner", "Content"];

  useEffect(() => {
    onSnapshot(collection(db, "products"), (snapshot) => {
      let newArray = [];
      snapshot.docs.map((x) => newArray.push({ ...x.data(), id: x.id }));
      newArray.sort(
        (a, b) =>
          new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())
      );
      setProductData(newArray);
    });
  }, []);

  const inputHandler = (e) => {
    setInputData((prevState) => {
      return {
        ...prevState,
        [e.target.id]: e.target.value,
      };
    });
  };

  const categoryHandler = (e) => {
    setInputData((prevState) => {
      prevState.kategori.push(e.target.value);
      return {
        ...prevState,
      };
    });
  };

  const submitHandler = async () => {
    setLoading(true);
    if (
      inputData.namaProduk &&
      inputData.harga > 0 &&
      inputData.deskripsi &&
      inputData.kategori.length > 0 &&
      productImages[0] &&
      varian[0] &&
      bannerImage
    ) {
      let newVarian = [];
      varian.map((x) =>
        newVarian.push({
          namaVarian: x.namaVarian,
          warna: x.warna,
        })
      );
      // Add Main Data
      await addDoc(collection(db, "products"), {
        namaProduk: inputData.namaProduk,
        kategori: inputData.kategori,
        deskripsi: inputData.deskripsi.replace(/\n/g, "<br>"),
        varian: newVarian,
        harga: inputData.harga,
        createdAt: Timestamp.now(),
      })
        .then(async (response) => {
          // Upload Product Images
          productImages.map(async (x) => {
            const storageRef = ref(storage, `/products/${x.name + v4()}`);
            // Upload File
            await uploadBytes(storageRef, x);
            // Image URL
            await getDownloadURL(storageRef).then(async (url) => {
              // Update FireStore
              await updateDoc(doc(db, "products", response.id), {
                images: arrayUnion(url),
              });
            });
          });

          // Upload Banner / Cover Image
          const bannerStorageRef = ref(
            storage,
            `banner/${bannerImage.name + v4()}`
          );
          await uploadBytes(bannerStorageRef, bannerImage);
          await getDownloadURL(bannerStorageRef).then(async (url) => {
            await updateDoc(doc(db, "products", response.id), {
              banner: url,
            });
          });
        })
        // Success Condition
        .then(() => {
          toast("Produk berhasil dibuat");
          // Clear all input data
          setInputData({
            namaProduk: "",
            deskripsi: "",
            kategori: [],
            harga: "",
          });
          setVarian([]);
          setBannerImage("");
          setLoading(false);
        })
        // Failed Condition
        .catch((error) => {
          if (error.message !== null) {
            toast(error.message);
            setLoading(false);
          } else {
            toast("Something went wrong !");
            setLoading(false);
          }
        });
    } else {
      toast("Pastikan semua data yang diperlukan sudah terisi");
      setLoading(false);
    }
  };

  const deleteProduct = async (id, bannerUrl, imagesUrl) => {
    try {
      await deleteDoc(doc(db, "products", id))
        .then(async () => {
          const prevRef = ref(storage, bannerUrl);
          deleteObject(prevRef);
        })
        .then(() => {
          imagesUrl?.map(async (x) => {
            const curRef = ref(storage, x);
            await deleteObject(curRef);
          });
        })
        .then(() => {
          toast("Produk berhasil dihapus");
        });
    } catch (error) {
      if (error.message !== null) {
        toast(error.message);
      } else {
        toast("Something went wrong !");
      }
    }
  };

  return (
    <div className="p-5 xl:px-0 max-w-[1180px] mx-auto">
      {editMode && (
        <EditModal
          editMode={editMode}
          setEditMode={setEditMode}
          currentProductId={currentProductId}
        />
      )}

      <div className="flex justify-center items-center">
        {modeList.map((x) => {
          return (
            <div
              onClick={() => setCurrentMode(x)}
              className={`${
                currentMode === x && "bg-black text-white"
              } border border-black py-2 text-center w-[100px] hover:bg-black hover:text-white transition-all cursor-pointer`}
            >
              {x}
            </div>
          );
        })}
      </div>

      {currentMode === "Banner" && <BannerAdmin />}
      {currentMode === "Content" && <ContentAdmin />}

      <div className={`${currentMode === "Produk" ? "block" : "hidden"}`}>
        <div className={`mt-12`}>
          <div className="text-[1.25rem] lg:text-[1.75rem] text-center">
            Buat Produk Baru
          </div>
          <div className="flex flex-col space-y-5">
            <div className="flex flex-col space-y-3">
              <label className="lg:text-[1.25rem]">Nama Produk</label>
              <input
                value={inputData.namaProduk}
                onChange={(e) => inputHandler(e)}
                id="namaProduk"
                placeholder="Masukkan Nama Produk"
                className="p-2 border outline-none"
              />
            </div>
            <div className="flex flex-col space-y-3">
              <label className="lg:text-[1.25rem]">Kategori</label>
              <div className="select-wrapper w-full">
                <select
                  onChange={(e) => categoryHandler(e)}
                  id="kategori"
                  className="p-2 border outline-none w-full"
                >
                  <option value="">Tambah Kategori Produk</option>
                  {Categories?.map((x) => {
                    return (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="flex flex-wrap mt-2">
                {inputData.kategori?.map((x, index) => {
                  return (
                    <div
                      onClick={() =>
                        setInputData((prevState) => {
                          prevState.kategori.splice(index, 1);
                          return { ...prevState };
                        })
                      }
                      className="flex items-center mr-3 space-x-2 py-2 px-3 rounded-full bg-gray-100"
                    >
                      <div>{x}</div>
                      <div className="p-1 bg-white rounded-full cursor-pointer">
                        <XMarkIcon className="h-[1rem] w-auto" />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col space-y-3">
                <label className="lg:text-[1.25rem]">Deskripsi Produk</label>
                <textarea
                  value={inputData.deskripsi}
                  onChange={(e) => inputHandler(e)}
                  id="deskripsi"
                  placeholder="Masukkan Deskripsi"
                  className="p-2 border outline-none h-[10rem]"
                />
              </div>
            </div>
            {/* Cover Image  */}
            <div className="flex flex-col space-y-3">
              <label className="lg:text-[1.25rem]">
                Upload Gambar Untuk Cover Tampilan Depan
              </label>
              <label
                htmlFor="banner-file"
                className="border rounded-md cursor-pointer w-full h-[20rem] flex justify-center bg-gray-100 items-center hover:brightness-90"
              >
                {bannerImage !== "" &&
                bannerImage !== null &&
                bannerImage !== undefined ? (
                  <img
                    className="h-full object-contain"
                    src={URL?.createObjectURL?.(bannerImage)}
                    alt=""
                  />
                ) : (
                  <div className="flex flex-col space-y-2">
                    <PhotoIcon className="h-[2rem] w-auto" />
                    <div>Cover Tampilan Depan</div>
                  </div>
                )}
              </label>
              <input
                onChange={(e) => setBannerImage(e.target.files[0])}
                className="h-0 w-0"
                id="banner-file"
                type="file"
              ></input>
            </div>
            {/* Cover Image End  */}

            {/* Upload Produk */}
            <div className="flex flex-col space-y-3">
              <label className="lg:text-[1.25rem]">
                Upload Gambar-Gambar Produk ( Gambar2 yang akan ditampilkan pada
                halaman detail produk )
              </label>

              {productImages?.map((x, index) => {
                return (
                  <div>
                    <label
                      htmlFor={`product-${index}`}
                      className="relative border rounded-md cursor-pointer w-full h-[20rem] flex justify-center bg-gray-100 items-center hover:brightness-90"
                    >
                      {index > 0 && (
                        <div
                          onClick={() =>
                            setProductImages((prevState) => {
                              prevState.splice(index, 1);
                              return [...prevState];
                            })
                          }
                          className="absolute right-[1%] top-[3%] p-2 bg-gray-200 rounded-full"
                        >
                          <TrashIcon className="h-[2rem] w-auto" />
                        </div>
                      )}
                      {productImages[index] !== "" &&
                      productImages[index] !== null &&
                      productImages[index] !== undefined ? (
                        <img
                          className="h-full object-contain"
                          src={URL?.createObjectURL?.(productImages[index])}
                          alt=""
                        />
                      ) : (
                        <div className="flex flex-col space-y-2">
                          <PhotoIcon className="h-[2rem] w-auto" />
                          <div>Gambar Produk {index + 1}</div>
                        </div>
                      )}
                    </label>
                    <input
                      onChange={(e) =>
                        setProductImages((prevState) => {
                          prevState[index] = e.target.files[0];
                          return [...prevState];
                        })
                      }
                      className="h-0 w-0"
                      id={`product-${index}`}
                      type="file"
                    ></input>
                  </div>
                );
              })}
              <button
                onClick={() =>
                  setProductImages((prevState) => {
                    prevState.push("");
                    return [...prevState];
                  })
                }
                className="flex items-center justify-center space-x-5 bg-gray-100 py-2"
              >
                <PlusIcon className="h-[1.5rem] w-auto" />
                <div>Tambah Gambar</div>
              </button>
            </div>
            {/* Upload Produk End  */}

            {/* Varian  */}
            <div className="flex flex-col space-y-3">
              <label className="lg:text-[1.25rem]">Varian Produk</label>
              {varian.map((x, index) => {
                return (
                  <div className="border p-5 drop-shadow-lg flex flex-col space-y-3">
                    <div className="flex items-center justify-between">
                      <div className="text-[1.25rem] font-semibold">
                        Tipe Varian {index + 1}
                      </div>
                      {index > 0 && (
                        <div
                          onClick={() =>
                            setVarian((prevState) => {
                              prevState.splice(index, 1);
                              return [...prevState];
                            })
                          }
                          className="hover:bg-gray-100 p-2 rounded-full cursor-pointer"
                        >
                          <TrashIcon className="h-[1.5rem] w-auto" />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label>Nama Varian</label>
                      <input
                        onChange={(e) =>
                          setVarian((prevState) => {
                            prevState[index].namaVarian = e.target.value;
                            return [...prevState];
                          })
                        }
                        className="p-2 outline-none"
                        placeholder="Masukkan Nama Varian ( Contoh : PUTIH 1200 X 700 )"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label>Warna</label>
                      <select
                        onChange={(e) =>
                          setVarian((prevState) => {
                            prevState[index].warna = e.target.value;
                            return [...prevState];
                          })
                        }
                        className="p-2 outline-none"
                      >
                        <option value="">Pilih Warna</option>
                        {Colors?.map((x) => {
                          return <option value={x.code}>{x.label}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Tombol Tambah Varian  */}
            <div
              onClick={() =>
                setVarian((prevState) => {
                  prevState.push({
                    warna: "",
                    namaVarian: "",
                    gambar: "",
                  });
                  return [...prevState];
                })
              }
              className="cursor-pointer flex justify-center items-center bg-gray-100 py-2 space-x-3 rounded-md"
            >
              <PlusIcon className="h-[1.5rem] w-auto" />
              <div>Tambah Varian Baru</div>
            </div>
            {/* Field Harga  */}
            <div className="flex flex-col space-y-3">
              <label className="lg:text-[1.25rem]">Harga</label>
              <input
                value={inputData.harga}
                type="number"
                onChange={(e) => inputHandler(e)}
                id="harga"
                placeholder="Masukkan Harga"
                className="p-2 border outline-none"
              />
            </div>
            <button
              onClick={() => submitHandler()}
              className="bg-black text-white py-3 rounded-md flex justify-center"
            >
              {loading ? <Loading /> : "Buat Produk"}
            </button>
          </div>
        </div>
        <div className="mt-5">
          <div className="text-[1.5rem]">List Product</div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-5 gap-5">
            {productData?.map((x) => {
              return (
                <div className="bg-white drop-shadow-lg border cursor-pointer">
                  <img
                    className="h-[15rem] 2xl:h-[25rem] object-cover w-full"
                    src={x?.banner}
                    alt=""
                  />
                  <div className="p-3 flex flex-col space-y-2">
                    <div className="text-[1.25rem]">{x.namaProduk}</div>
                    <div className="text-[1.25rem] font-semibold">
                      Rp {Number(x.harga).toLocaleString("id")}
                    </div>
                    <div>{x.deskripsi.substring(0, 100) + "..."}</div>
                    <div className="flex items-center justify-end space-x-2">
                      <PencilSquareIcon
                        onClick={() => {
                          setEditMode(true);
                          setCurrentProductId(x.id);
                        }}
                        className="h-[2.5rem] w-auto p-2 hover:bg-gray-100 rounded-full"
                      />
                      <TrashIcon
                        onClick={() => deleteProduct(x.id, x.banner, x.images)}
                        className="h-[2.5rem] w-auto p-2 hover:bg-gray-100 rounded-full"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
